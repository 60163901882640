#home-button {
  position: absolute;
  top: 46%;
  left: 50%;
  width: 12em;
  transform: translate(-50%, -50%);
  cursor: pointer;
  color: #fff;
  background: $react-purple-1; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    $react-purple-1,
    $react-purple-2
  ) !important; /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    $react-purple-1,
    $react-purple-2
  ) !important;
}

#reset-button {
  position: absolute;
  top: 90%;
  left: 50%;
  width: 12em;
  transform: translate(-50%, -50%);
  cursor: pointer;
  font-size: small;
  color: #252526;
  background: none;
}

#reset-button:hover {
  color: #2185d0;
}

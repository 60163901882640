$pink: #dc6cd8 !important;
$green: #47cab0;
$blue: #9adcff;
$dark-blue: #3ba3f8;
$darker-blue: #2185d0;
$url-blue: #4183c4;
$dark-grey: #252526;
$lighter-grey: #333;
$dg-grey: #35373b;
$dark-mode-grey: #1e1e1e;
$dark-mode-grey-2: #232526;
$home-screen-grey: #c5cddd;
$background-grey: #a7adba;
$background-grey-2: #979ead;
$seg-header-grey: #858a94;
$header-grey: rgba(0, 0, 0, 0.6);
$orange: #d8bb79;
$peach: #cf9176;
$react-purple-1: #6834bd;
$react-purple-2: #7946b6;
$white: #fff;

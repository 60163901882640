// list item top segment
.ui.items:not(.unstackable) > .item {
  margin-top: 0em;
}

#top-list-single-segment {
  height: 4.75rem;
}

#top-list-double-segment {
  height: 6.5rem;
}

#top-list-triple-segment {
  height: 7.75rem;
}

#bottom-segment {
  margin-bottom: 3.5em;
}

#bottom-list-segment {
  text-transform: capitalize;
  color: $dg-grey;
  height: 5.8rem;
}

#bottom-list-single-segment {
  text-transform: capitalize;
  color: $dg-grey;
  height: 5rem;
}

#detailed-segment {
  margin-bottom: 1.75em;
  padding-bottom: 4em;
}

// headers

#wiki-header {
  color: $white;
}

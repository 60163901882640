/* navbar styles */

.ui.inverted.menu {
  background: $react-purple-1;
  background: -webkit-linear-gradient(
    to bottom,
    $react-purple-1,
    $react-purple-2
  );
  background: linear-gradient(to bottom, $react-purple-1, $react-purple-2);
}

.ui.menu .item > img:not(.ui) {
  margin-right: 1.5em !important;
  margin-left: 0em !important;
}

@media only screen and (min-width: 768px) {
  .ui.menu:not(.secondary):not(.text):not(.tabular):not(.borderless)
    > .container
    > .item:not(.right):not(.borderless):first-child {
    padding-left: 0;
  }

  #menu-icon {
    margin-right: -0.75em;
  }
}

@media only screen and (max-width: 768px) {
  #menu-icon {
    margin-right: 0;
  }
}

#menu-icon:hover {
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1);
}

.ui.main.container,
.main.segment {
  margin-top: 5em;
}

@media only screen and (max-width: 768px) {
  #home-dashboard {
    margin-top: -1.5rem;
    padding-bottom: 5em;
  }
}

.ui.grid > .column:not(.row) {
  padding-top: 1rem;
  padding-bottom: 0;
}

#module-menu {
  background: $react-purple-1;
  background: -webkit-linear-gradient(
    to right,
    $react-purple-1,
    $react-purple-2
  );
  background: linear-gradient(to right, $react-purple-1, $react-purple-2);
  margin-bottom: -0.22em;
  overflow: hidden;
  text-align: center;
  font-size: 0.9em;
  color: $white;
  width: 100%;
  margin-top: 10em;
}

#settings-menu {
  background: $react-purple-1;
  background: -webkit-linear-gradient(
    to right,
    $react-purple-1,
    $react-purple-2
  );
  background: linear-gradient(to right, $react-purple-1, $react-purple-2);
  color: $white;
  opacity: 0.85;
}

#logo {
  width: 2em;
}

#logo:hover {
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1);
}

#wiki-menu {
  background: $react-purple-2;
  opacity: 0.9;
}

.ui.menu:not(.vertical) .item {
  color: $white !important;
}

.ui.blue.menu .active.item,
.ui.menu .blue.active.item {
  color: $blue !important;
}

.ui.inverted.menu {
  height: 3.35em;
}

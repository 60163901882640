body {
  background-color: $background-grey;
}

#avatar {
  width: 1em;
  height: 1em;
}

img.ui.image {
  display: inline-block;
}

.ui.fluid.image,
.ui.fluid.image img,
.ui.fluid.image svg,
.ui.fluid.images,
.ui.fluid.images img,
.ui.fluid.images svg {
  height: 190px;
}

.ui.form .inline.fields {
  margin-left: 2rem !important;
}

.ui .checkbox {
  margin: 0.75em 0.5em 0 0;
}

#ltg,
#darker-blue {
  color: $darker-blue;
}

#url-blue {
  color: $url-blue;
}

#calendar-container {
  margin-left: 0 !important;
  margin-right: 0 !important;
  height: 235px;
  overflow-y: scroll;
}

@media only screen and (min-width: 768px) {
  #calendar-container {
    height: 300px;
  }

  #homepageStyles {
    height: 397px;
    overflow-y: scroll;
  }
}

#dg,
.ui.center.aligned.header,
.ui.centered.header {
  color: $dg-grey;
}

#sig {
  overflow: auto;
}

#button-remove-goal {
  margin: 20px 0;
}

#plus-button {
  margin: 0.25em 0 0 1em;
}

#view-button {
  opacity: 0.9;
}

#cancel-button {
  opacity: 0.82;
}

#view-li-buttons {
  margin: 20px 4px;
}

#view-li-single-buttons {
  margin: 5px 4px;
}

#dark-grey {
  color: $dark-grey;
}

#dropdown-sort {
  margin: 0;
  color: $dark-mode-grey !important;
  margin-top: 0.5rem;
}

#dropdown-select {
  margin-left: -1em;
  margin-top: 1rem !important;
}

#dropdown-select-no-search {
  margin-left: 0.25rem !important;
  margin-top: 1rem !important;
}

.dropzone {
  border: dashed 3px #eee;
  border-radius: 5px;
  padding-top: 30px;
  text-align: center;
}

.dropzone--isActive {
  border: dashed 3px lightblue;
}

#date-picker {
  padding: 0.5em;
}

.ui.list,
ol.ui.list,
ul.ui.list {
  margin-bottom: -0.5em;
}

.ui.comments .comment .comments {
  padding-bottom: 0 !important;
  padding-left: 0.5em !important;
}

#capitalize-role {
  text-transform: capitalize;
  color: $header-grey;
}

.ui.checkbox input.hidden + label {
  color: $dg-grey;
}

#infinite-container {
  max-height: 38vh;
  overflow: auto;
}

@media only screen and (min-width: 1192px) {
  .ui.grid {
    padding-left: 5%;
  }
  #infinite-container {
    max-height: 38vh;
    overflow: auto;
  }
}

@media only screen and (min-width: 1300px) {
  .ui.grid {
    padding-left: 0;
  }
}
#dashboard-card {
  margin-top: 0;
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  #dashboard-card {
    margin-top: 0.5rem;
  }
}

.signature-holder {
  position: relative;
  border: 1px solid gray;
  display: block;
  margin: 0;
  -webkit-appearance: none;
  padding: 0.78571429em 1em;
  background: $white;
  border: 1px solid rgba(34, 36, 38, 0.15);
  outline: 0;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  -webkit-transition: color 0.1s ease, border-color 0.1s ease;
  transition: color 0.1s ease, border-color 0.1s ease;
  font-size: 1em;
  line-height: 1.2857;
  resize: vertical;
  label {
    position: absolute;
    top: 5px;
    left: 5px;
    color: lightgray !important;
  }
  button {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
}
